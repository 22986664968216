<template>
  <div class="main-bg">
    <div class="content">
      <div class="wrapper">
        <div class="bg-transparent header">
          <nav class="navbar navbar-dark">
            <div class="container">
              <router-link
                class="navbar-brand"
                to="/"
              >
                <img
                  :src="logoBrand"
                  height="40"
                >
              </router-link>

              <h1
                class="flex-grow-1 text-center title"
                style="white-space: normal"
              >
                Calcula el precio de tu curso de inglés en Irlanda
              </h1>

              <div class="navbar-nav flex-row hidden-mobile">
                <router-link
                  v-if="!isLoggedIn"
                  class="nav-item nav-link active"
                  to="/login"
                >
                  <i class="fas fa-user" />
                </router-link>
                <router-link
                  v-if="isLoggedIn"
                  class="nav-item nav-link active mr-3"
                  to="/admin"
                >
                  <i class="fas fa-user" />
                </router-link>
                <a
                  v-if="isLoggedIn"
                  class="nav-item nav-link active"
                  href="#"
                  @click.prevent="logOut"
                >
                  <i class="fas fa-power-off" />
                </a>
              </div>
            </div>
          </nav>
        </div>

        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    backgroundImg: '/img/main-bg.jpeg',
    logoBrand: `${process.env.VUE_APP_API_URL}public/images/logo-cotizador.jpg`,
  }),
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    ...mapActions(['logout']),
    logOut() {
      this.logout().then(() => this.$router.push({ name: 'Login' }));
    },
  },
};
</script>

<style lang="scss">
.header > .navbar {
  background-color: green;
}
.header .title {
  color: white;
  font-weight: 600;
  font-size: 1.25em;
}

@media (max-width: 768px) {
  .header .title {
    display: none;
  }
}
</style>
